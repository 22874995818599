import React, { useState } from 'react';
import './Card.css'; // Custom CSS file
import farmer from '../../../assets/farmer/Farmer2.jpg';
import farmer1 from '../../../assets/farmer/sad_farmer1.jpg';
import farmer3 from '../../../assets/farmer/farmer.jpg';

const cardData = [
  {
    frontImage: farmer,
    backText: 'Description 1',
  },
  {
    frontImage: farmer1,
    backText: 'Description 2',
  },
  {
    frontImage: farmer3,
    backText: 'Description 3',
  },
  {
    frontImage: farmer3,
    backText: 'Description 4',
  },
];

function FlipCard() {
  const [flipped, setFlipped] = useState([false, false, false, false]);

  const handleClick = (index) => {
    setFlipped(flipped.map((item, idx) => (idx === index ? !item : item)));
  };

  return (
  <div className='card-out-here py-4'>
      <div className="container">
      <div className="row scene justify-content-center">
        {cardData.map((card, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 d-flex justify-content-center">
            <div
              className={`card ${flipped[index] ? 'is-flipped' : ''}`}
              onClick={() => handleClick(index)}
            >
              <div className="card__face card__face--front">
                <img src={card.frontImage} alt={`Front ${index + 1}`} />
              </div>
              <div className="card__face card__face--back">
                <p>{card.backText}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  );
}

export default FlipCard;

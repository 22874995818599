import React from 'react'
import './New1.css'
const New1 = () => {
  return (
    <div>
      <div className='Container-fluif'>
        <div className='new-cont-img'>
            <div>
                <h1>Join the eCROP Movement</h1>
                <p>Be a part of the future of farming. Leverage smart technologies to enhance your agricultural practices and achieve greater success.</p>
            </div>
        </div>
        <div className='twoimg'>
            <h1>            hellow words
            </h1>
        </div>
      </div>
      
    </div>
  )
}

export default New1

import React from "react";
import './Rating.css'
const data = [
  {
    title: "20,000+",
    description: "Smart Devices Deployed",
  },
  {
    title: "15,000+",
    description: "Satisfied Farmers",
  },
  {
    title: "50+",
    description: "Countries Served",
  },
  {
    title: "100+",
    description: "Innovations Introduced",
  },
];

const testimonials = [
  {
    name: "John Doe",
    date: "July 10, 2024",
    feedback:
      "Ecrop's solutions have transformed our farm operations, making them more efficient and sustainable.",
    imageUrl:
      "https://via.placeholder.com/50", // You can replace this with the actual image URL
    rating: 5,
  },
  {
    name: "Jane Smith",
    date: "June 15, 2024",
    feedback:
      "The technology provided by Ecrop has significantly increased our crop yields and reduced waste.",
    imageUrl:
      "https://via.placeholder.com/50", // You can replace this with the actual image URL
    rating: 5,
  },
];

const Rating = () => {
  return (
    <div className="Rating p-8">
      <section className="text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">Empowering Farmers Globally</h2>
        <p className="text-gray-600">
          Join a community dedicated to transforming agriculture through smart technology.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
          {data.map((item, index) => (
            <div key={index} className="bg-green-50 p-6 rounded-lg shadow-md">
              <h3 className="text-2xl font-semibold text-green-600 mb-2">
                {item.title}
              </h3>
              <p className="text-gray-700">{item.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section className="space-y-8">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="flex items-start space-x-4 p-6 border-b">
            <img
              src={testimonial.imageUrl}
              alt={testimonial.name}
              className="w-16 h-16 rounded-full"
            />
            <div>
              <div className="flex space-x-1 text-green-500 mb-2">
                {Array.from({ length: testimonial.rating }).map((_, i) => (
                  <span key={i}>⭐</span>
                ))}
              </div>
              <p className="text-gray-700">{testimonial.feedback}</p>
              <h4 className="font-semibold text-gray-900 mt-2">{testimonial.name}</h4>
              <p className="text-gray-500 text-sm">{testimonial.date}</p>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Rating;

import React from 'react';
import './Slider.css'; // Import your CSS file
import slide from '../../assets/images/bruce-mars.jpg'
const Banner = () => {
  return (
    <div className="banner">
      <div className="slider" style={{ '--quantity': 10 }}>
        <div className="item" style={{ '--position': 1 }}>
          <img src={slide} alt="Dragon 1" />
        </div>
        <div className="item" style={{ '--position': 2 }}>
          <img src={slide}  alt="Dragon 2" />
        </div>
        <div className="item" style={{ '--position': 3 }}>
          <img src={slide}  alt="Dragon 3" />
        </div>
        <div className="item" style={{ '--position': 4 }}>
          <img src={slide}  alt="Dragon 4" />
        </div>
        <div className="item" style={{ '--position': 5 }}>
          <img src={slide}  alt="Dragon 5" />
        </div>
        <div className="item" style={{ '--position': 6 }}>
          <img src={slide} alt="Dragon 6" />
        </div>
        <div className="item" style={{ '--position': 7 }}>
          <img src={slide}  alt="Dragon 7" />
        </div>
        <div className="item" style={{ '--position': 8 }}>
          <img src={slide}  alt="Dragon 8" />
        </div>
        <div className="item" style={{ '--position': 9 }}>
          <img src={slide} alt="Dragon 9" />
        </div>
        <div className="item" style={{ '--position': 10 }}>
          <img src={slide}  alt="Dragon 10" />
        </div>
      </div>
      <div className="content">
        <h1 data-content="CSS ONLY">CSS ONLY</h1>
        <div className="author">
          <h2>LUN DEV</h2>
          <p><b>Web Design</b></p>
          <p>Subscribe to the channel to watch many interesting videos</p>
        </div>
        <div className="model"></div>
      </div>
    </div>
  );
};

export default Banner;

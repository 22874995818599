// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    z-index: 99;
    background-color: var(--white);
    background-image: linear-gradient(180deg, var(--white), var(--grey-200));
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
    flex-direction: column;
    display: flex;
    position: relative;
   }
 .footer-container{
  background:#09200b;
  color: aliceblue;
 }
  .footer-link {
    color: var(--grey);
    font-size: 16px;
    line-height: 1;
  }
  
  @media screen and (max-width: 991px) {
    .div-block-47 {
      border-bottom: 1px solid var(--grey-100);
      width: 100%;
      height: 1px;
      display: none;
    }
  }
  .footer-legal-container {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 16px;
    display: flex;
  }
  
  .legal-link {
    color: var(--grey-500);
    max-width: 480px;
    font-size: 14px;
    line-height: 1;
  }
  
 
  
  
  .social-links {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .footer-header{
    color:aliceblue;
  }`, "",{"version":3,"sources":["webpack://./src/Component/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,8BAA8B;IAC9B,wEAAwE;IACxE,4BAA4B;IAC5B,6BAA6B;IAC7B,sBAAsB;IACtB,aAAa;IACb,kBAAkB;GACnB;CACF;EACC,kBAAkB;EAClB,gBAAgB;CACjB;EACC;IACE,kBAAkB;IAClB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE;MACE,wCAAwC;MACxC,WAAW;MACX,WAAW;MACX,aAAa;IACf;EACF;EACA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,2BAA2B;IAC3B,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,cAAc;EAChB;;;;;EAKA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,SAAO;IACP,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,eAAe;EACjB","sourcesContent":[".footer {\r\n    z-index: 99;\r\n    background-color: var(--white);\r\n    background-image: linear-gradient(180deg, var(--white), var(--grey-200));\r\n    border-top-left-radius: 48px;\r\n    border-top-right-radius: 48px;\r\n    flex-direction: column;\r\n    display: flex;\r\n    position: relative;\r\n   }\r\n .footer-container{\r\n  background:#09200b;\r\n  color: aliceblue;\r\n }\r\n  .footer-link {\r\n    color: var(--grey);\r\n    font-size: 16px;\r\n    line-height: 1;\r\n  }\r\n  \r\n  @media screen and (max-width: 991px) {\r\n    .div-block-47 {\r\n      border-bottom: 1px solid var(--grey-100);\r\n      width: 100%;\r\n      height: 1px;\r\n      display: none;\r\n    }\r\n  }\r\n  .footer-legal-container {\r\n    grid-column-gap: 16px;\r\n    grid-row-gap: 16px;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n    width: 100%;\r\n    padding: 16px;\r\n    display: flex;\r\n  }\r\n  \r\n  .legal-link {\r\n    color: var(--grey-500);\r\n    max-width: 480px;\r\n    font-size: 14px;\r\n    line-height: 1;\r\n  }\r\n  \r\n \r\n  \r\n  \r\n  .social-links {\r\n    grid-column-gap: 16px;\r\n    grid-row-gap: 16px;\r\n    flex: 1;\r\n    justify-content: flex-end;\r\n    align-items: center;\r\n    display: flex;\r\n  }\r\n\r\n  .footer-header{\r\n    color:aliceblue;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

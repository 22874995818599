import React from 'react'
import Header from './Component/Header/Header';
// index.js or App.js
import './tailwind.css';
 // In your index.js or App.js
import 'bootstrap/dist/css/bootstrap.min.css';
 import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Product from './Component/Product/Product'
 import Slider from './Component/Slider/Slider'
import Features from './Component/Feature/Features';
import Footer from './Component/Footer/Footer';
 // import Footer from './Component/Footer/Footer1';
import Mainsection from './Component/Home/Mainsection';
import ContactForm from './Component/Contact/Contact';
import FlipCard from './Component/Home/Card/FlipCard';
import Explore from './Component/Explore/Explore.js'
import About from './Component/About/About';
import Slideimage from './Component/Slider/Slideimage.js'
// import New1 from './Component/Newcomp/New1.js';
  function App() {
  return (
    <>
    <BrowserRouter>
    
   
    <Routes>
     <Route path="/product" element={<Product/>} />
    <Route path="/" element={<Header />} />
    <Route path="/slider" element={<Slider />} />
    <Route path="/feature" element={<Features/>} />
    <Route path="/mainsection" element={<Mainsection/>} />
    <Route path="/contact" element={<ContactForm/>} />
    <Route path="/card" element={<FlipCard/>} />
    <Route path="/explore" element={<Explore/>} />
    <Route path="/About-us" element={<About/>} />
    <Route path="/slide" element={<Slideimage/>} />
    </Routes>
 <Footer/>
    </BrowserRouter>
    {/* <New1/> */}
    </>
  )
}

export default App
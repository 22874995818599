import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
// import Join from '../join us/Join';  
import {Link} from 'react-router-dom'
import './Section.css'
import New1 from '../Newcomp/New1';
const Slider = () => {
  const images = [
    { src: 'https://cdn.wegic.ai/assets/onepage/ai/image/49c06da3-42f5-4147-8c6a-cd40485be0a7.jpeg', title: 'Smart Farming', description: 'Innovative farming solutions', icon: 'tractor' },
    { src: 'https://cdn.wegic.ai/assets/onepage/ai/image/322f0a09-2d5c-4cb2-8081-1dc18763edd2.jpeg', title: 'Drone Monitoring', description: 'Precision agriculture', icon: 'drone' },
    { src: 'https://cdn.wegic.ai/assets/onepage/ai/image/508bc7f9-501c-446d-b65a-af5a948ee6e0.jpeg', title: 'Sustainable Practices', description: 'Eco-friendly farming', icon: 'leaf' },
    { src: 'https://cdn.wegic.ai/assets/onepage/ai/image/385f6530-db98-4062-8650-57a7be99033b.jpeg', title: 'Automated Harvesting', description: 'Efficient crop management', icon: 'robot' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
    <Container className="my-5">
      <Row>
        <Col md={6}>
          <div className="d-flex flex-column justify-content-center h-100">
            <h1 className="display-4 emp-main">Empowering Farmers through Ercop Technology</h1>
            <p className="lead">
              Join us in transforming traditional farming with cutting-edge electronic and smart technologies. Enhance your productivity, sustainability, and profitability.
            </p>
            <ul className="list-unstyled d-flex gap-3">
              <li className="d-flex align-items-center">
                <i className="fas fa-seedling fa-2x me-2"></i>
                <span className="text-uppercase">Sustainable Growth</span>
              </li>
              <li className="d-flex align-items-center">
                <i className="fas fa-chart-line fa-2x me-2"></i>
                <span className="text-uppercase">Increased Productivity</span>
              </li>
              <li className="d-flex align-items-center">
                <i className="fas fa-globe fa-2x me-2"></i>
                <span className="text-uppercase">Global Reach</span>
              </li>
            </ul>
            {/* You can uncomment and use Bootstrap's Button component if needed */}
            <div className="ab-btn">
                  <Link to="/feature" className="ch-btn-style-2">More Details</Link>
                </div>
          </div>
        </Col>
        <Col md={6}>
          <Carousel interval={3000} controls={false} indicators={false}>
            {images.map((image, index) => (
              <Carousel.Item key={index} className={classNames({ 'd-block': currentIndex === index })}>
                <img className="d-block w-100" src={image.src} alt={`Slide ${index}`} />
                <Carousel.Caption>
                  <h3>{image.title}</h3>
                  <p>{image.description}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
    {/* <Join/> */}
    <New1/>
    </>
  );
};

export default Slider;

import React from 'react'
import Slideimage from '../Slider/Slideimage'
import Rating from './Rating'
function Product() {
  return (
    <>
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8 dark:bg-slate-800">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-6xl dark:text-white/90">
          <div className="_editable_jwu41_1 undefined" data-link="link=&amp;target=_blank&amp;text=Innovative%20Farming%20Solutions">
            Innovative Farming Solutions
          </div>
        </h2>
        <p className="mt-6 text-lg leading-8 text-slate-700 dark:text-white/90">
          <div className="_editable_jwu41_1 undefined" data-link="link=&amp;target=_blank&amp;text=Discover%20how%20AgriTechSmart%20combines%20tradition%20with%20technology%20to%20boost%20your%20farm%27s%20productivity%20and%20sustainability.">
            Discover how AgriTechSmart combines tradition with technology to boost your farm's productivity and sustainability.
          </div>
        </p>
      </div>
    </div>
    <Slideimage/>
    <Rating/>
 </>

  )
}

export default Product
 import { useRef, useEffect } from 'react';
import styles from './page.module.css'
import video from '../../assets/medias/Forest.mov';
export default function Explore() {

  const container = useRef(null);
  const stickyMask = useRef(null);

  const initialMaskSize = .8;
  const targetMaskSize = 50;
  const easing = 0.15;
  let easedScrollProgress = 0;

  useEffect( () => {
    requestAnimationFrame(animate)
  }, [])

  const animate = () => {
    const maskSizeProgress = targetMaskSize * getScrollProgress();
    stickyMask.current.style.webkitMaskSize = (initialMaskSize + maskSizeProgress) * 100 + "%";
    requestAnimationFrame(animate)
  }

  const getScrollProgress = () => {
    // Make sure the height is calculated properly for mobile
    const containerHeight = container.current.getBoundingClientRect().height;
    const scrollProgress = (window.scrollY - container.current.getBoundingClientRect().top) / (containerHeight - window.innerHeight);
    const delta = scrollProgress - easedScrollProgress;
    easedScrollProgress += delta * easing;
    return easedScrollProgress;
  };

  return (
    <main className={styles.main}>
      <div ref={container} className={styles.container}>
        <div ref={stickyMask} className={styles.stickyMask}>
          <video autoPlay muted loop>
            <source src={video} type="video/mp4"/>
          </video>
        </div>
      </div>
    </main>
  )
}
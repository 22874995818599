// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Product/join_i.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-cont-img {
    background-color: green; /* Fallback color in case the image doesn't load */
    text-align: center;
    padding: 100px;
    color: white;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Center the image within the container */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
    width: 100%;
}

.new-cont-img div{
    width:70%;
    background-color: #00000061;
    padding:50px;
}
.twoimg{
    background-color: red;
    height:100vh;
    text-align: center;
    /* background-image: url('../../assets/background-1.webp'); */
    background-color: bisque;
    background-repeat: no-repeat;
    background-size: cover;
    padding:30px 0;
}`, "",{"version":3,"sources":["webpack://./src/Component/Newcomp/New1.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB,EAAE,kDAAkD;IAC3E,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,yDAAyD;IACzD,sBAAsB,EAAE,kDAAkD;IAC1E,2BAA2B,EAAE,0CAA0C;IACvE,4BAA4B,EAAE,sCAAsC;IACpE,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,SAAS;IACT,2BAA2B;IAC3B,YAAY;AAChB;AACA;IACI,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,6DAA6D;IAC7D,wBAAwB;IACxB,4BAA4B;IAC5B,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".new-cont-img {\r\n    background-color: green; /* Fallback color in case the image doesn't load */\r\n    text-align: center;\r\n    padding: 100px;\r\n    color: white;\r\n    background-image: url('../../assets/Product/join_i.jpeg');\r\n    background-size: cover; /* Ensures the image covers the entire container */\r\n    background-position: center; /* Center the image within the container */\r\n    background-repeat: no-repeat; /* Prevents the image from repeating */\r\n    height: 100vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    background-attachment: fixed;\r\n    width: 100%;\r\n}\r\n\r\n.new-cont-img div{\r\n    width:70%;\r\n    background-color: #00000061;\r\n    padding:50px;\r\n}\r\n.twoimg{\r\n    background-color: red;\r\n    height:100vh;\r\n    text-align: center;\r\n    /* background-image: url('../../assets/background-1.webp'); */\r\n    background-color: bisque;\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    padding:30px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

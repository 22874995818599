// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rating{
    background-color: #fff3cd;
 }`, "",{"version":3,"sources":["webpack://./src/Component/Product/Rating.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;CAC5B","sourcesContent":[".Rating{\r\n    background-color: #fff3cd;\r\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

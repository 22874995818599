import React from 'react';
import './Footer.css'; // Import the CSS file
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="container p-4 pb-0">
          <section>
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase footer-header mb-4 font-weight-bold">Precision Grow</h6>
                <p>
                  Here you can use rows and columns to organize your footer
                  content. Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit.
                </p>
              </div>
              
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                <h6 className="text-uppercase footer-header mb-4 font-weight-bold">Contact</h6>
                <p><i className="fas fa-home mr-3"></i> Parel, Mumbai-400012 INDIA</p>
                <p><i className="fas fa-envelope mr-3"></i> mail@precisiongrow.in</p>
                <p><i className="fas fa-phone mr-3"></i> +91 7710884111</p>
                <p><i className="fas fa-print mr-3"></i> +91 22 46165798</p>
              </div>
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                <h6 className="text-uppercase footer-header mb-4 font-weight-bold">Follow us</h6>
                <Link className="btn footer-header  btn-floating m-1" to="/" role="button">
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link className="btn  footer-header btn-floating m-1" to="#!" role="button">
                  <i className="fab fa-google"></i>
                </Link>
                <Link className="btn footer-header  btn-floating m-1" to="#!" role="button">
                  <i className="fab fa-instagram"></i>
                </Link>
                <Link className="btn footer-header btn-floating m-1" target='_blank' to="/" role="button">
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import axios from 'axios';
import './Contact.css'; // Import custom styles for additional adjustments

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [status, setStatus] = useState('');
 
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };
 
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with:', formData);

    try {
      const response = await axios.post('https://ecbsf-backend.azurewebsites.net/api/contact/send', formData);
      
      if (response.status === 200) {
        setStatus('Message sent successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: ''
        });
        console.log("Successfully sent message");
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setStatus('Failed to send message.');
    }
  };

 

  return (
    <div className="Contact-main container-fixed py-5 contact-outt">
      <div className="Inner-Contact position-relative">
        <span className="big-circle"></span>
        <div className="row">
          <div className="col-md-6 contact-info">
            <h3 className="title">Get in Touch with ERCOP</h3>
            <p className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
              dolorum adipisci recusandae praesentium dicta!
            </p>
            <div className="info">
  <div className="align-items-center mb-3">
    <i className="fas fa-map-marker-alt icon"></i>
    <p>92 Cherry Drive Uniondale, NY 11553</p>
  </div>
  <div className="align-items-center mb-3">
    <i className="fas fa-envelope icon"></i>
    <p>mail@precisiongrow.co.in</p>
  </div>
  <div className="align-items-center mb-3">
    <i className="fas fa-phone icon"></i>
    <p>123-456-789</p>
  </div>
</div>
          </div>
          <div className="col-md-6 contact-form">
            <form onSubmit={handleSubmit} autoComplete="off">
              <h3 className="title pb-3">Contact us</h3>
              <div className="form-group pb-3 input-container">
                <input
                  type="text"
                  name="name"
                  className="form-control input"
                  // ref={addInputRef}
                  placeholder="Username"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group pb-3 input-container">
                <input
                  type="email"
                  name="email"
                  className="form-control input"
                  // ref={addInputRef}
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group pb-3 input-container">
                <input
                  type="tel"
                  name="phone"
                  className="form-control input"
                  // ref={addInputRef}
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group pb-3 input-container textarea">
                <textarea
                  name="message"
                  className="form-control input"
                  // ref={addInputRef}
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button type="submit" className="btn button--pan submit btn-light">Send</button>
              {/* {status && <p className="status-message">{status}</p>} */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

import { useRef } from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';
import styles from './style.module.scss'; // Ensure the path is correct
import Drone from '../../assets/Product/Drone Monitoring.jpeg';
import Renewable from '../../assets/Product/Renewable.jpeg';
import techImage from '../../assets/join.jpeg'

// Data for the sliders
const slider1 = [
    { color: "#e3e5e7", src: Drone },
    { color: "#21242b", src: Renewable },
    { color: "#e3e3e3", src: techImage },
    { color: "#21242b", src: techImage }
];

const slider2 = [
    { color: "#d4e3ec", src: techImage },
    { color: "#21242b", src: techImage },
    { color: "#d7d4cf", src: Renewable },
    { color: "#21242b", src: Drone }
];

export default function Index() {
    const container = useRef(null);
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ["start end", "end start"]
    });

    const x1 = useTransform(scrollYProgress, [0, 1], [0, 150]);
    const x2 = useTransform(scrollYProgress, [0, 1], [0, -150]);
    const height = useTransform(scrollYProgress, [0, 0.9], [50, 0]);

    return (
        <div ref={container} className={styles.slidingImages}>
            <motion.div style={{ x: x1 }} className={styles.slider}>
                {slider1.map((project, index) => (
                    <div key={index} className={styles.project} style={{ backgroundColor: project.color }}>
                        <div className={styles.imageContainer}>
                            <img
                                alt={`image-${index}`}
                                src={project.src}
                            />
                        </div>
                    </div>
                ))}
            </motion.div>
            <motion.div style={{ x: x2 }} className={styles.slider}>
                {slider2.map((project, index) => (
                    <div key={index} className={styles.project} style={{ backgroundColor: project.color }}>
                        <div className={styles.imageContainer}>
                            <img
                                alt={`image-${index}`}
                                src={project.src}
                            />
                        </div>
                    </div>
                ))}
            </motion.div>
            <motion.div style={{ height }} className={styles.circleContainer}>
                <div className={styles.circle}></div>
            </motion.div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import './Features.css';
import feat from '../../assets/images/bg-coworking.jpeg'
import feat1 from '../../assets/feature1.jpeg';
import feat2 from '../../assets/feature2.jpeg';
import feat3 from '../../assets/fetaure3.jpeg';
import feat4 from '../../assets/feature4.jpeg';

const Features = () => {
  const items = [
    { img: feat4, title: 'Automated Harvesting', description: 'Efficient crop management' },
    { img: feat1, title: 'Drone Monitoring', description: 'Precision agriculture' },
    { img: feat2, title: 'Smart Farming', description: 'Innovative farming solutions' },
    { img: feat3, title: 'Sustainable Practices', description: 'Eco-friendly farming' },
    { img: feat, title: 'feature 05', description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, neque? Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum, ex.' },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const showfeature = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="feature">
      <div className="list">
        {items.map((item, index) => (
           <div className={`item ${index === activeIndex ? 'active' : ''}`} key={index}>
            <img src={item.img} alt={`img${index + 1}`} />
            <div className="content">
               
              <h2>{item.title}</h2>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="arrows">
        <button id="prev" onClick={prevSlide}>&lt;</button>
        <button id="next" onClick={nextSlide}>&gt;</button>
      </div>
      <div className="thumbnail">
        {items.map((item, index) => (
          <div
            className={`item ${index === activeIndex ? 'active' : ''}`}
            key={index}
            onClick={() => showfeature(index)}>
            <img src={item.img} alt={`img${index + 1}`} />
            <div className="content">{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_slidingImages__vAziO {
  display: flex;
  flex-direction: column;
  gap: 3vw;
  position: relative;
  margin-top: 200px;
  background-color: white;
  z-index: 1;
}
.style_slidingImages__vAziO .style_slider__qGsne {
  display: flex;
  position: relative;
  gap: 3vw;
  width: 120vw;
  left: -10vw;
}
.style_slidingImages__vAziO .style_slider__qGsne .style_project__pexuP {
  width: 25%;
  height: 20vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_slidingImages__vAziO .style_slider__qGsne .style_project__pexuP .style_imageContainer__wYXyc {
  position: relative;
  width: 80%;
  height: 80%;
}
.style_slidingImages__vAziO .style_slider__qGsne .style_project__pexuP .style_imageContainer__wYXyc img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.style_slidingImages__vAziO .style_circleContainer__uBAcT {
  background-color: red;
  position: relative;
  margin-top: 100px;
}
.style_slidingImages__vAziO .style_circleContainer__uBAcT .style_circle__hTg8e {
  height: 1550%;
  width: 120%;
  left: -10%;
  border-radius: 0 0 50% 50%;
  background-color: white;
  z-index: 1;
  position: absolute;
  box-shadow: 0px 60px 50px rgba(0, 0, 0, 0.748);
}`, "",{"version":3,"sources":["webpack://./src/Component/Slider/style.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;EACA,UAAA;AACJ;AACI;EACI,aAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;AACR;AACQ;EACI,UAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACZ;AACY;EACI,kBAAA;EACA,UAAA;EACA,WAAA;AAChB;AACgB;EACI,iBAAA;EACA,WAAA;EACA,YAAA;AACpB;AAKI;EACI,qBAAA;EACA,kBAAA;EACA,iBAAA;AAHR;AAKQ;EACI,aAAA;EACA,WAAA;EACA,UAAA;EACA,0BAAA;EACA,uBAAA;EACA,UAAA;EACA,kBAAA;EACA,8CAAA;AAHZ","sourcesContent":[".slidingImages {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 3vw;\r\n    position: relative;\r\n    margin-top: 200px;\r\n    background-color: white;\r\n    z-index: 1;\r\n\r\n    .slider {\r\n        display: flex;\r\n        position: relative;\r\n        gap: 3vw;\r\n        width: 120vw;\r\n        left: -10vw;\r\n\r\n        .project {\r\n            width: 25%;\r\n            height: 20vw;\r\n            display: flex;\r\n            align-items: center;\r\n            justify-content: center;\r\n\r\n            .imageContainer {\r\n                position: relative;\r\n                width: 80%;\r\n                height: 80%;\r\n\r\n                img {\r\n                    object-fit: cover;\r\n                    width: 100%;\r\n                    height: 100%;\r\n                }\r\n            }\r\n        }\r\n    }\r\n\r\n    .circleContainer {\r\n        background-color: red;\r\n        position: relative;\r\n        margin-top: 100px;\r\n\r\n        .circle {\r\n            height: 1550%;\r\n            width: 120%;\r\n            left: -10%;\r\n            border-radius: 0 0 50% 50%;\r\n            background-color: white;\r\n            z-index: 1;\r\n            position: absolute;\r\n            box-shadow: 0px 60px 50px rgba(0, 0, 0, 0.748);\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slidingImages": `style_slidingImages__vAziO`,
	"slider": `style_slider__qGsne`,
	"project": `style_project__pexuP`,
	"imageContainer": `style_imageContainer__wYXyc`,
	"circleContainer": `style_circleContainer__uBAcT`,
	"circle": `style_circle__hTg8e`
};
export default ___CSS_LOADER_EXPORT___;

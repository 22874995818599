import React, { useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './About.css';
import logo from '../../assets/logo/logo.png'
// import speech from '../../assets/SPEECH-IMAGES.webp';
// import device from '../../assets/device.jpg';
import certificate from '../../assets/GROUP-IMAGES.webp';
import star1 from '../../assets/star1.png';
import star2 from '../../assets/star2.png';
import star3 from '../../assets/star2.png';
import elogoshape from '../../assets/icar-logo.png';
// import banner from '../../assets/home-banner-w.webp';
import { Link } from 'react-router-dom';
import video from '../../assets/about-vi.mp4'
// import Slideimage from '../Slider/Slideimage'
const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false,
    });
  }, []);
  
  // const [videoSrc, setVideoSrc] = useState(null);

  
 
  return (
    <>
    <div className='about-outer-Main'>
           <nav className="navbar navbar-expand-lg navbar-light  ">
        <div className="container  ">
           <img src={logo} alt='logo' className='img-logo' />
          
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="  navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/product">Products</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/Explore">Explore</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/Contact">Contact</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white"   to="/about-us">About Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="about-outer container-fluid"  >
        <div className="container">
          <div className="about-info-div col-md-12">
            <div className="about-shape"></div>
            <h1>About eCROP <br /> Platform</h1>
            <div className="about-section__stars">
              <img src={star1} alt="" className="star1" />
              <img src={star2} alt="" className="star2" />
              <img src={star3} alt="" className="star3" />
            </div>
          </div>
        </div>
      </section>
      </div>
         <div className="  py-16 bg-green-100 flex flex-wrap items-center mx-auto px-4 aos-init aos-animate" data-aos="fade-up">
          <div className="w-full md:w-1/2">
            <img src={certificate} alt="Presentation" className="main-image" />
            {/* <div className="overlay-image-container">
              <img src={device} alt="Event" className="overlay-image" />
            </div> */}
          </div>
          <div className="content-about col-md-12 col-lg-5 offset-lg-1">
            <h2>Who We Are</h2>
            <p className='text-justify'>
              Precision Grow, a distinguished unit of Tech Visit IT Pvt Ltd, has embarked on a groundbreaking journey in revolutionizing smart farming technology through a strategic collaboration with the prestigious Indian Council of Agricultural Research (ICAR). On June 5th, 2024, commemorating World Environment Day, Precision Grow sealed a pivotal contract for the manufacturing and dissemination of the groundbreaking e-Crop technology. This innovative agricultural solution seamlessly amalgamates cutting-edge technology with sustainable agricultural practices, aiming to optimize crop production while mitigating adverse environmental impacts.
            </p>
          </div>
        </div>
        {/* <Slideimage/> */}
       <div className="container-fluid abt-y">
        <img src={elogoshape} alt="ICAR Logo"/>
        <div className="header-about">Addressing Food Security</div>
        <div className="container description">
            The global challenge of achieving food security is compounded by population growth and diminishing resources. Precision agriculture offers tailored solutions to enhance production and minimize environmental harm, yet a notable yield gap persists. Smart farming integrates technology with traditional practices, empowering informed decision-making and resource optimization to narrow yield gaps and bolster food security efforts.
        </div>
      </div>
      <div className="steps">
            <div className="step step11" data-step="01">
                <p>Food security challenge intensifies due to growing populations and limited resources, exacerbated by diminishing cultivable land availability.</p>
            </div>
            <div className="step" data-step="02">
                <p>Precision agriculture offers tailored solutions to boost production and minimize environmental harm, yet a notable yield gap persists globally.</p>
            </div>
            <div className="step" data-step="03">
                <p>Smart farming merges technology with traditional practices, enabling informed decisions, resource optimization, and heightened productivity.</p>
            </div>
            <div className="step step11" data-step="04">
                <p>Integration of real-time data, sensors, and analytics holds significant promise in narrowing yield gaps and strengthening food security initiatives.</p>
            </div>
        </div>
      {/* <section className="smart-ecrop">
        <div className="container">
            <div className="row about-bg-row">
                <h1>How Smart e-Crop Transforms Farming</h1>
                <div className="col-md-6 text-section">
                    <div className="feature-ab">
                        <h2>Precision Agriculture</h2>
                        <p>Smart e-Crop's precision agriculture capabilities ensure that crops receive the right amount of water, nutrients, and care at the right time, maximizing growth and minimizing waste.</p>
                    </div>
                    <div className="feature-ab">
                        <h2>Sustainable Practices</h2>
                        <p>By monitoring environmental conditions and adjusting inputs accordingly, Smart e-Crop supports sustainable farming practices that protect natural resources and promote long-term soil health.</p>
                    </div>
                    <div className="feature-ab">
                        <h2>Labor Efficiency</h2>
                        <p>Automated systems reduce the need for manual labor, allowing farmers to focus on strategic tasks and improving overall farm management.</p>
                    </div>
                    <div className="feature-ab">
                        <h2>Market Competitiveness</h2>
                        <p>With enhanced traceability and quality assurance, Smart e-Crop gives farmers a competitive edge in the marketplace, meeting consumer demand for transparency and sustainability.</p>
                    </div>
                </div>
                <div className="col-md-6 image-section">
                    <img src={banner} className='w-100' alt=""/>
                </div>
            </div>
        </div>
      </section> */}
      <div className="container bgggg">
        <p className="team-skills-one__col-text">
          Smart eCrop is the embodiment of smart farming, offering a comprehensive solution that integrates technology and agriculture seamlessly. Developed by ICAR-CTCRI and marketed by Precision Grow, Smart eCrop is poised to revolutionize the farming industry, making smart farming accessible and effective for all.
        </p>
        <p className="team-skills-one__col-text">Embrace the future of agriculture with Smart eCrop and experience the benefits of increased productivity, cost savings, sustainability, and improved decision-making.</p>
      </div>
      <div className="container-fluid text-center py-5">
      <h3 className="mb-3">eCROP</h3>
      <h1 className="mb-4 fw-bold">  our vision.</h1>
      <p className="text-muted mb-5">
        We are a team of passionate people whose mission is to digitally enable
        every aspect of farming to create a positive impact in the lives of ~300
        million Indian farmers and agri workers.
      </p>
      <div className="row justify-content-center align-items-center mb-5">
            <div className="col-md-6">
                
                <div className="embed-responsive embed-responsive-16by9">
                 
                        <video
                            className="embed-responsive-item"
                            src={video}
                            controls
                            style={{ width: '100%', height: '100%' }}
                        />
                 
                </div>
            </div>
        </div>
      <div className="row justify-content-center">
        <div className="col-8">
          <div className="dot-container d-flex justify-content-between">
            {Array.from({ length: 6 }).map((_, index) => (
              <div
                key={index}
                className={`dot ${
                  index % 2 === 0
                    ? "bg-green"
                    : "bg-yellow"
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
      </>
    // </div>
  );
};

export default About;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/medias/mask.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_main__DbeqZ{
    /* margin-bottom: 100vh; */
  }
  
  .page_container__kUR\\+A{
    position: relative;
    height: 300vh;
    background-color: rgb(137, 206, 147);
  }
  
  .page_stickyMask__ARQ7n{
    display: flex;
    overflow: hidden;
    position: sticky;
    top: 0;
    height: 119vh;
    align-items: center;
    justify-content: center;
    mask-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    mask-position: 50% center;
    mask-repeat: no-repeat;
    mask-size: 80%;
  }
  /* .stickyMask{
    display: flex;
    overflow: hidden;
    position: sticky;
    top: 0;
    height: 119vh;
    align-items: center;
    justify-content: center;
    mask-image: url('../../assets/medias/mask.svg');
    mask-position: 50% center;
    mask-repeat: no-repeat;
    mask-size: 80%;
  } */
  
  .page_stickyMask__ARQ7n video{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }`, "",{"version":3,"sources":["webpack://./src/Component/Explore/page.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,oCAAoC;EACtC;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,MAAM;IACN,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mDAA+C;IAC/C,yBAAyB;IACzB,sBAAsB;IACtB,cAAc;EAChB;EACA;;;;;;;;;;;;KAYG;;EAEH;IACE,YAAY;IACZ,WAAW;IACX,iBAAiB;EACnB","sourcesContent":[".main{\r\n    /* margin-bottom: 100vh; */\r\n  }\r\n  \r\n  .container{\r\n    position: relative;\r\n    height: 300vh;\r\n    background-color: rgb(137, 206, 147);\r\n  }\r\n  \r\n  .stickyMask{\r\n    display: flex;\r\n    overflow: hidden;\r\n    position: sticky;\r\n    top: 0;\r\n    height: 119vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n    mask-image: url('../../assets/medias/mask.svg');\r\n    mask-position: 50% center;\r\n    mask-repeat: no-repeat;\r\n    mask-size: 80%;\r\n  }\r\n  /* .stickyMask{\r\n    display: flex;\r\n    overflow: hidden;\r\n    position: sticky;\r\n    top: 0;\r\n    height: 119vh;\r\n    align-items: center;\r\n    justify-content: center;\r\n    mask-image: url('../../assets/medias/mask.svg');\r\n    mask-position: 50% center;\r\n    mask-repeat: no-repeat;\r\n    mask-size: 80%;\r\n  } */\r\n  \r\n  .stickyMask video{\r\n    height: 100%;\r\n    width: 100%;\r\n    object-fit: cover;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `page_main__DbeqZ`,
	"container": `page_container__kUR+A`,
	"stickyMask": `page_stickyMask__ARQ7n`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import hist from '../../assets/farmer/farmer.jpg'
import './History.css';
import { Link } from 'react-router-dom';
import Section from '../Home/Section';
 import FlipCard from './Card/FlipCard';
  function History() {
  return (
   <>
   <div className='combine-history-section'>
     <div className="container history pb-5">
         <div className="row history-main align-items-center pb-5 pt-5">
             <div className="ch-choose-img-wrap col-lg-7 col-md-12 col-sm-12">
              <div className="choose-img-left">
                <img className='image-history' src={hist} alt="" />
              </div>
              <div className="choose-img-right">
                   <img className='image-history' src={hist} alt="" />
               </div>
            </div>
           <div className="col-lg-5 col-md-12 col-sm-12">
               <div className="ch-top-title">
                 <h2>Before Ecrop The Condition of Farmer</h2>
              </div>
              <p className='p-history'>
              Some say Smart Farming can trace its roots back to the late 1990s when tractors first started using GPS to map out farms and cropping maps. In those days the technology was fraught with problems. </p> 
              <p className='p-history'>
              The term Climate Smart Agriculture was defined by the Food and Agriculture Organisation of the United Nations (FAO) at the Hague Conference on Agriculture, Food Security and Climate Change in 2010.</p>
              <p className='p-history'>
              It is difficult, therefore, to pinpoint any one moment when farming 'went smart'. It is more of an ongoing process. Some say Smart Farming can trace its roots back to the late 1990s when tractors first started using GPS to map out farms and cropping maps.
              </p>
                 <div className="ab-btn">
                  <Link to="/History-more" className="ch-btn-style-2">More Details</Link>
                </div>
           </div>
        </div>
     </div>
     {/* <div className="container Card-Main d-flex justify-content-center align-items-center">
      <div className='main-card-div'>
      <div className="card-container">
        <div className="card">
          <div className="front">
            <img src={hist} alt='h' />
           </div>
          <div className="back d-flex flex-column justify-content-center align-items-center">
            <h1>Back of Card</h1>
            <p>Additional info on the back of the card</p>
          </div>
        </div>
      </div>
      </div>
    </div> */}
    <FlipCard/>
    {/* <Card/> */}
     <Section/>
     </div>
      </>
 

  )
}

export default History
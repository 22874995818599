// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Empower-main.w-full.flex.flex-col.pl-1.gap-10 {
    padding-left: 1vh;
    padding-right: 1vh;
}
 
ul.Row-UL.flex.gap-8.items-center {
    padding: 0;
}

 .fas {
    color: green;
}
.emp-main{
font-weight: 500;
}

@media  (max-width:1024px){
    /* ul.Row-UL.flex.gap-8.items-center {
        font-size: smaller;
    }    */
    .Empower-main.w-full.flex.flex-col.gap-10 {
        padding-left: 1vh;
        padding-right: 1vh;
    }
    ul.Row-UL.flex.gap-8.items-center {
        gap: 0;
        font-size: smaller;
    }
}

@media (max-width:425px) {
    ul.list-unstyled.d-flex.gap-3 {
        font-size: small;
    }
}`, "",{"version":3,"sources":["webpack://./src/Component/Home/Section.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;CAEC;IACG,YAAY;AAChB;AACA;AACA,gBAAgB;AAChB;;AAEA;IACI;;UAEM;IACN;QACI,iBAAiB;QACjB,kBAAkB;IACtB;IACA;QACI,MAAM;QACN,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".Empower-main.w-full.flex.flex-col.pl-1.gap-10 {\r\n    padding-left: 1vh;\r\n    padding-right: 1vh;\r\n}\r\n \r\nul.Row-UL.flex.gap-8.items-center {\r\n    padding: 0;\r\n}\r\n\r\n .fas {\r\n    color: green;\r\n}\r\n.emp-main{\r\nfont-weight: 500;\r\n}\r\n\r\n@media  (max-width:1024px){\r\n    /* ul.Row-UL.flex.gap-8.items-center {\r\n        font-size: smaller;\r\n    }    */\r\n    .Empower-main.w-full.flex.flex-col.gap-10 {\r\n        padding-left: 1vh;\r\n        padding-right: 1vh;\r\n    }\r\n    ul.Row-UL.flex.gap-8.items-center {\r\n        gap: 0;\r\n        font-size: smaller;\r\n    }\r\n}\r\n\r\n@media (max-width:425px) {\r\n    ul.list-unstyled.d-flex.gap-3 {\r\n        font-size: small;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;


import React from 'react';
import History from '../Home/History';
import {Link} from 'react-router-dom'; 
import './Header.css'; 
import logo from '../../assets/logo/logo.png'

// import Section from '../Home/Section'
const Header = () => {
  return (
    <>
    <header className='home sticky-header'>
      <nav className="navbar navbar-expand-lg navbar-light  ">
        <div className="container">
        <img src={logo} alt='logo' className='img-logo' />
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="  navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/product">Products</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/Explore">Explore</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/Contact">Contact</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" target="_blank" to="/about-us">About Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <section className="h-text text-center py-5">
        <span>eCROP</span>
        <h1>Smart Farming</h1>
        <br />
        <Link className="btn btn-primary"   to="/Explore">Explore</Link>
      </section>
    </header>
     {/* <Section/> */}
     <History/>
     </>
  );
}

export default Header;
